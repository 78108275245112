import React, { useState } from "react"
import { Link } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"

import { postForm } from "../utils/api"
import { useInput } from "../utils/hooks"
import * as routes from "../utils/routes"
import Layout from "../components/Layouts/Base"
import SEO from "../components/seo"
import Popup from "../components/Popup"

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY

const ErrorMessage = ({ error }) => {
  if (!error) return null

  return <span className="block my-2 text-red-500 text-sm">{error}</span>
}

const ContactPage = () => {
  const [state, setState] = useState(null)
  const [errors, setErrors] = useState({})
  const [recaptcha, setRecaptcha] = useState("")
  const [privacyAccepted, setPrivacyAccepted] = useState(false)
  const [firstName, setFirstName] = useInput("")
  const [lastName, setLastName] = useInput("")
  const [company, setCompany] = useInput("")
  const [email, setEmail] = useInput("")
  const [message, setMessage] = useInput("")

  return (
    <Layout>
      <SEO title="Contact" />

      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="relative max-w-xl mx-auto">
          <svg
            className="absolute left-full transform translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div className="text-center">
            <h2 className="text-2xl leading-9 font-display font-bold text-gray-900 md:text-4xl sm:leading-10">
              Get in touch
            </h2>
            <p className="mt-4 md:text-lg leading-6 text-gray-500">
              If you have a sales, technical enquiry or just want to say hello
              we would be happy to hear from you.
            </p>
          </div>
          <div className="mt-12">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-recaptcha="true"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              onSubmit={async e => {
                e.preventDefault()
                const formErrors = {}
                if (!privacyAccepted)
                  formErrors.privacyAccepted =
                    "Please accept our Privacy policy"
                if (firstName.length < 1)
                  formErrors.firstName = "Please enter your forename"
                if (lastName.length < 1)
                  formErrors.lastName = "Please enter your surname"
                if (company.length < 1)
                  formErrors.company = "Please enter your company name"
                if (email.length < 1)
                  formErrors.email = "Please enter your email address"
                if (!email.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i))
                  formErrors.email = "Please enter a valid email address"
                if (message.length < 1)
                  formErrors.message = "Please enter a message"
                if (recaptcha.length < 1)
                  formErrors.recaptcha = "Please complete the challenge"

                if (Object.keys(formErrors).length) {
                  setErrors(formErrors)
                  return
                }
                setErrors({})
                setState("submitting")
                try {
                  const resp = await postForm("/api/contactForm", {
                    "form-name": "contact",
                    "g-recaptcha-response": recaptcha,
                    firstName,
                    lastName,
                    company,
                    email,
                    message,
                  })
                  if (resp.status === 200) {
                    setState("success")
                  } else {
                    const json = await resp.json()
                    setState("failed")
                    setErrors({ recaptcha: json.error })
                  }
                } catch (e) {
                  setState("failed")
                  console.error("Error posting form", e.message)
                }
              }}
            >
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  First name
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={setFirstName}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                </div>
                <ErrorMessage error={errors.firstName} />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Last name
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={setLastName}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                </div>
                <ErrorMessage error={errors.lastName} />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Company
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    id="company"
                    type="text"
                    name="company"
                    value={company}
                    onChange={setCompany}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                </div>
                <ErrorMessage error={errors.company} />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={setEmail}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                </div>
                <ErrorMessage error={errors.email} />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Message
                </label>
                <div className="mt-1 relative rounded-md">
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    value={message}
                    onChange={setMessage}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  ></textarea>
                </div>
                <ErrorMessage error={errors.message} />
              </div>
              <div className="sm:col-span-2">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <span
                      role="checkbox"
                      tabIndex="0"
                      aria-checked="false"
                      onClick={() => setPrivacyAccepted(!privacyAccepted)}
                      className={`${
                        privacyAccepted ? `bg-blue-600` : `bg-gray-200`
                      } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${
                          privacyAccepted ? `translate-x-5` : `translate-x-0`
                        } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                      ></span>
                    </span>
                  </div>
                  <div className="ml-3">
                    <p className="text-base leading-6 text-gray-500">
                      By selecting this, you agree to the{" "}
                      <Link
                        to={routes.privacy}
                        className="font-medium text-gray-700 underline"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </p>
                    <ErrorMessage error={errors.privacyAccepted} />
                  </div>
                </div>
              </div>
              <div className={`sm:col-span-2`}>
                <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={setRecaptcha} />
                <ErrorMessage error={errors.recaptcha} />
              </div>
              <div className="sm:col-span-2">
                <span className="w-full inline-flex">
                  <button
                    type="submit"
                    className="w-full inline-flex font-display items-center justify-center px-6 py-3 border border-transparent text-lg leading-6 font-bold rounded-md text-white bg-payhere hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                  >
                    Let's talk
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      {state === "success" && (
        <Popup
          title="Message sent"
          body="Thanks for contacting us, we’ll be in touch soon."
          buttonText="Done"
          onClose={() => setState(null)}
          icon={() => (
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg
                className="h-6 w-6 text-green-600"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
        />
      )}
      {state === "failed" && (
        <Popup
          title="Message failed"
          body="We had a problem sending your message, check all the fields and try again."
          buttonText="Close"
          onClose={() => setState(null)}
          icon={() => (
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg
                className="h-6 w-6 text-red-600"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                ></path>
              </svg>
            </div>
          )}
        />
      )}
    </Layout>
  )
}

export default ContactPage
